import React from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'src/common/eureka';

const styles = () => ({
  title: {
    textAlign: 'center',
  },
  description: {
    textAlign: 'center',
    fontSize: 'small',
  },
});

export default function MoreThanMaxNumberNotifications({ remainingNotifications }) {
  const { t } = useTranslation();
  const useStyles = createUseStyles(styles);
  const classes = useStyles();

  return (
    <>
      <hr />
      <div className={classes.title}>
        {t('Header_Notification_Remaining_Notifications', { count: remainingNotifications })}
      </div>
      <div className={classes.description}>{t('Header_Notification_Unread_Notifications')}</div>
      <div className={classes.description}>{t('Header_Notification_Clear_Notifications')}</div>
    </>
  );
}
