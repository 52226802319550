export const SCREEN_L_SIZE = 1024;

export const DST_FF = 'RM.7428.DAYLIGHT.SAVING';

export const THEME_FF = 'rm.13227.horizon';

export const REMOVE_DARK_THEME_FF = 'RM.39023.ADOPTION.OF.HORIZON.THEME.NO.UI.IMPROVEMENTS';

export const DISPOSITION_HOME_ROUTE = '/rm-disposition';

export const THEME_LIST = [
  {
    name: 'sap_horizon',
    displayName: 'SAP Morning Horizon',
    themeId: 'sap_horizon',
    icon: '',
    style: {
      backgroundColor: 'rgb(255, 255, 255)',
      border: '1px solid #758ca4',
      boxShadow: 'inset 0 0 2px #758ca4',
    },
    order: '10',
  },
  {
    name: 'sap_horizon_dark',
    displayName: 'SAP Evening Horizon',
    themeId: 'sap_horizon_dark',
    icon: '',
    style: {
      backgroundColor: 'rgb(29, 45, 63)',
      border: '1px solid #1D2D3E',
      boxShadow: 'inset 0 0 2px #a9b4be',
    },
    order: '20',
  },
  {
    name: 'sap_horizon_hcw',
    displayName: 'SAP High Contrast White',
    themeId: 'sap_horizon_hcw',
    icon: '',
    style: {
      backgroundColor: 'rgb(255, 255, 255)',
      border: '1px solid #000',
    },
    order: '30',
  },
  {
    name: 'sap_horizon_hcb',
    displayName: 'SAP High Contrast Black',
    themeId: 'sap_horizon_hcb',
    icon: '',
    style: {
      backgroundColor: 'rgb(0, 0, 0)',
      border: '1px solid #FFF',
    },
    order: '40',
  },
];
