import Demo from './Demo';
import { getFeatureToggle } from 'src/common/eureka';
import { featureToggles } from 'src/common/feature-toggles';

const route = {
  path: '/demo',
  name: 'Demo',
  component: Demo,
  get disable() {
    return getFeatureToggle(featureToggles.DISABLE_DEMO_TOOL.name);
  },
};

export default route;
