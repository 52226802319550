import { Button, Title, TitleLevel, FlexBox, Text, ButtonDesign } from '@ui5/webcomponents-react';
import { useTranslation } from 'src/common/eureka';
import { closeShellDialog } from '../../plugins/dialog.plugin';
import { ABOUT_PAGE_LINKS } from '../constants';
import logo from '../logo';
import useStyles from './AboutPage.styles';

const AboutPage = () => {
  const { t } = useTranslation(process.env.APP_NAME);
  const classes = useStyles();

  return (
    <>
      <FlexBox justifyContent="Center" direction="Column" className={classes.content}>
        <div className={classes.logoContainer}>
          <img className={classes.sapLogo} src={logo} alt="SAP" />
        </div>
        <Title wrappingType="Normal" className={classes.title} level={TitleLevel.H2}>
          {t('AboutSAPIRM')}
        </Title>

        <Text className={classes.versionText}>
          {`${t('AboutReleaseNotes')} `}
          <a style={{ textDecoration: 'none' }} href={ABOUT_PAGE_LINKS.RELEASE_NOTES}>
            {`${t('AboutHelpPortal')}.`}
          </a>
        </Text>

        <div className={classes.copyrightContainer}>
          <Text className={classes.versionText}>{t('AboutCopyright')}</Text>
          <FlexBox direction="Row" justifyContent="SpaceBetween" className={classes.links}>
            <a className={classes.link} href={ABOUT_PAGE_LINKS.WEBSITE}>
              {t('AboutIRMWebsite')}
            </a>
            <a className={classes.link} href={ABOUT_PAGE_LINKS.TERMS_OF_USE}>
              {t('AboutTermsOfUse')}
            </a>
            <a className={classes.link} href={ABOUT_PAGE_LINKS.PRIVACY_POLICY}>
              {t('AboutPrivacy')}
            </a>
          </FlexBox>
        </div>
        <div className={classes.border} />
      </FlexBox>
      <FlexBox alignItems="Center" justifyContent="End" className={classes.buttonContainer}>
        <Button
          design={ButtonDesign.Transparent}
          className={classes.button}
          onClick={() => closeShellDialog()}
        >
          {t('Close')}
        </Button>
      </FlexBox>
    </>
  );
};

export default AboutPage;
