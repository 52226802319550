import React from 'react';
import { FlexBox, FlexBoxDirection, Link, Text, Title, TitleLevel } from '@ui5/webcomponents-react';
import { createUseStyles } from 'react-jss';

const styles = () => ({
  supportTitleSections: {
    marginBottom: '10px',
  },
  supportDescriptions: {
    fontSize: 'small',
  },
  supportLinks: {
    marginBottom: '30px',
  },
});
const useStyles = createUseStyles(styles);

export function SupportItem({ supportItem }) {
  const classes = useStyles();

  return (
    <FlexBox direction={FlexBoxDirection.Column} style={{ height: 'auto' }}>
      <Title level={TitleLevel.H4} className={classes.supportTitleSections}>
        <strong>{supportItem.title}</strong>
      </Title>
      <Text className={classes.supportDescriptions}>{supportItem.message}</Text>
      <Link
        className={classes.supportLinks}
        href={supportItem.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {supportItem.linkDescription}
      </Link>
    </FlexBox>
  );
}
