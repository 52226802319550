import React, { useEffect, useState } from 'react';
import { Icon, SegmentedButton, SegmentedButtonItem, Switch } from '@ui5/webcomponents-react';
import cn from 'classnames';
import eureka from 'eureka';
import sap from './images/sap';
import {
  handleCarrierReceived,
  handleInTransit,
  handleDelivered,
  getAutoTransitEnabled,
  putAutoTransitEnabled,
} from './demoService';
import './style.less';

const { Spinner } = eureka.components;

const stagesForMail = [
  { label: 'Carrier Received', action: handleCarrierReceived },
  { label: 'In Transit', action: handleInTransit },
  { label: 'Delivered', action: handleDelivered },
].map(stage => ({ ...stage, id: stage.label }));

const stagesForStore = [].map(stage => ({
  ...stage,
  id: stage.label,
}));

const stagesForKeepItem = [].map(stage => ({
  ...stage,
  id: stage.label,
}));

const returnTypes = [
  { id: 'RETURN_IN_STORE', label: 'Return in Store' },
  { id: 'RETURN_BY_MAIL', label: 'Return by Mail' },
  { id: 'KEEP_ITEM', label: 'Keep Item' },
];

const stagesMap = {
  RETURN_IN_STORE: stagesForStore,
  RETURN_BY_MAIL: stagesForMail,
  KEEP_ITEM: stagesForKeepItem,
};

const getStages = returnTypeId => stagesMap[returnTypeId];

const getValueFromButtonEvent = cb => e => cb(e.target.dataset.value);

const getValueFromInputEvent = cb => e => cb(e.target.value);

const getAction = stages => id => stages.find(stage => stage.id === id).action;

const useResult = () => {
  const [value, setValue] = useState(null);
  const setResult = x => {
    setValue(x);
    setTimeout(() => setValue(null), 1500);
  };
  return [value, setResult];
};

const Demo = () => {
  const [orderIdentifier, setOrderId] = useState('');
  const [returnTypeId, setReturnTypeId] = useState(returnTypes[1].id);
  const [isError, setError] = useState(false);
  const [isWaiting, setWaiting] = useState(false);
  const [result, setResult] = useResult();

  const stages = getStages(returnTypeId);

  const handleButtonClick = async id => {
    if (!orderIdentifier) {
      setError(true);
      return;
    }
    setWaiting(true);
    const action = getAction(stages)(id);
    try {
      await action(orderIdentifier, returnTypeId);
      setWaiting(false);
      setResult('success');
    } catch (error) {
      let msg = 'error';
      if (error.response) {
        const statusCode = error.response.status;
        if (statusCode === 404) {
          msg = 'the order cannot be found';
        } else if (statusCode === 400) {
          msg = 'Return Type is incorrect';
        }
      }
      setWaiting(false);
      setResult(msg);
    }
  };

  const handleReturnTypeSelection = async value => {
    setReturnTypeId(value);
  };

  const handleOnFocus = () => {
    isError && setError(false);
  };

  const [isAutoTransitEnabled, setIsAutoTransitEnabled] = useState(false);
  const [isAutoTransitLoading, setIsAutoTransitLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setIsAutoTransitLoading(true);

      const { data } = await getAutoTransitEnabled();

      setIsAutoTransitEnabled(data?.enabledAutomatedLogisticStatusEmulation);
      setIsAutoTransitLoading(false);
    }
    fetchData();
  }, []);

  const handleSwitch = async () => {
    setIsAutoTransitLoading(true);
    try {
      await putAutoTransitEnabled(!isAutoTransitEnabled);
      setIsAutoTransitEnabled(!isAutoTransitEnabled);
    } catch (e) {
      console.error(e);
    } finally {
      setIsAutoTransitLoading(false);
    }
  };

  return (
    <div className="demoPage">
      <div className="contentCard">
        <div className="header">
          <img className="sapLogo" src={sap} alt="SAP" />
          <div className="simulatorLabel">
            <span>SAP Intelligent Returns Management Event Simulator</span>
          </div>
        </div>
        <div className="form">
          <label className="inputField">
            <span className="inputField__label">Return Order ID:</span>
            <input
              onChange={getValueFromInputEvent(setOrderId)}
              onFocus={handleOnFocus}
              className={cn('inputField__input', { 'inputField__input--error': isError })}
            />
          </label>
          <label className="inputField">
            <span className="inputField__label">Return Type:</span>
            <SegmentedButton>
              {returnTypes.map(({ label, id }) => (
                <SegmentedButtonItem
                  key={id}
                  data-value={id}
                  pressed={id === returnTypeId}
                  onClick={getValueFromButtonEvent(handleReturnTypeSelection)}
                >
                  {label}
                </SegmentedButtonItem>
              ))}
            </SegmentedButton>
          </label>
          <label className="transit">
            <Switch
              onChange={handleSwitch}
              className="switch"
              checked={isAutoTransitEnabled}
              disabled={isAutoTransitLoading}
            />
            <span className="switch__label">Automation Transit to Final Logistic Status</span>
          </label>
        </div>
        {!isAutoTransitEnabled && (
          <div className="stages">
            {stages.map((state, index, arr) => (
              <>
                <button
                  className="stages__item step"
                  onClick={getValueFromButtonEvent(handleButtonClick)}
                  data-value={state.id}
                  key={state.id}
                >
                  {state.label}
                </button>
                {++index < arr.length && (
                  <div className="stepSpace">
                    <Icon name="arrow-right" />
                  </div>
                )}
              </>
            ))}
          </div>
        )}
        <div className="result">
          {result && (
            <div
              className={cn('result__text', {
                'result__text--success': result === 'success',
                'result__text--error': result !== 'success',
              })}
            >
              {result}
            </div>
          )}
        </div>
      </div>
      <div className="signature">
        <img className="signature__sapLogo" src={sap} alt="sap" />
        <div className="signature__label">Powered by SAP Cloud Platform</div>
      </div>
      {isWaiting && (
        <div className="spinner_box">
          <div className="spinner_overlay" />
          <Spinner className="spinner" />
        </div>
      )}
    </div>
  );
};

export default Demo;
