/* eslint-disable arrow-body-style */
/* This is the Root component mainly initializes Redux and React Router. */
import React, { useEffect, useRef, useState } from 'react';
import { Provider } from 'react-redux';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import {
  mergeSettings,
  renderRouteConfigV3,
  notEmptyStr,
  initAppTheme,
  setAppTheme,
} from './App.helper';
import { ThemeProvider, MessageStrip, MessageStripDesign } from '@ui5/webcomponents-react';
import store from './common/store';
import routeConfig from './common/routeConfig';
import history from './common/history';
import {
  getURLParam,
  TestingLocales,
  isDispositionPermissionOnly,
  hasReturnOrderCreate,
  hasSettingsWrite,
} from './common/Utils';
import { MicroFrontend } from './features/common';
import { getRandom } from './features/common/Utils';
import WebAssistant from './common/web-assistant/WebAssistant';
import {
  addConfig,
  setCsrfToken,
  getConfig,
  Spinner,
  initI18n,
  setLanguage,
  configManagerSetLanguage,
  updateFeatureToggle,
  getFeatureToggle,
  MessageToast,
  eventBus,
} from './common/eureka';
import { DST_FF, DISPOSITION_HOME_ROUTE } from 'src/common/constants';
import '@eureka/ui-managers/src/styles/layout.css';
import { enablePendo } from './common/init';

let config = null;
let lng = 'en-US';
let themeId;
initAppTheme();

export const loader = () => {
  return <div>Loading...</div>;
};

// eslint-disable-next-line arrow-body-style
export const renderError = msg => {
  return (
    <MessageStrip
      style={{ marginTop: '10px', marginRight: '10px' }}
      design={MessageStripDesign.Negative}
      hideCloseButton={true}
    >
      {msg}
    </MessageStrip>
  );
};

export const MicroFrontendWrapper = ({ history, match, host, name, settings, user }) => {
  if (!settings) {
    console.error('Settings for microfrontends is empty, which is not allowed');
    return null;
  }
  return (
    <MicroFrontend
      history={history}
      match={match}
      host={host}
      name={name}
      config={config}
      settings={settings}
      user={user.current}
      eventBus={eventBus}
    />
  );
};

export const renderMicroFrontendRoutes = ({ mfdRouters, history, settings, user }) => {
  const routes = [];
  mfdRouters.forEach(app => {
    app.routers.forEach(route => {
      routes.push(
        <Route
          key={route + getRandom()}
          exact
          path={route}
          component={props => (
            <MicroFrontendWrapper
              {...props}
              host={app.host}
              name={app.name}
              history={history}
              settings={settings}
              user={user}
            />
          )}
        />,
      );
    });
  });
  return routes;
};

const onFetchConfigSuccess = ({ manifest, data, setData, setMicroFrontends }) => {
  const { cdn } = manifest.config;
  const shell = manifest['shell-ui'];
  let shellHost = '';
  const microFrontends = [];

  if (shell) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      // http://localhost:2020
      shellHost = `${cdn}:${shell.port}`;
    } else {
      // https://cdn.eurekasap.io/eureka/shell-ui//dbbe6d4/
      // eslint-disable-next-line no-lonely-if
      if (shell.location) {
        shellHost = shell.location;
      } else {
        shellHost = `${cdn}/static/${shell.name}/${shell.commit}`;
      }
    }
  } else {
    console.error('Shell config is missed in config.json, please check');
  }
  const i18nNamespaces = { 'irmo-shell': shellHost };

  manifest.components.forEach(comp => {
    let host = '';
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      // http://localhost:2001
      host = `${cdn}:${comp.port}`;
    } else {
      // https://cdn.eurekacloud.io/static/shell-ui/44c6effde2773258a9282458f26e162b2544be5c/
      // https://cdn.eurekasap.io/eureka/settlement-ui/dbbe6d4/
      // eslint-disable-next-line no-lonely-if
      if (comp.location) {
        host = comp.location;
      } else {
        host = `${cdn}/static/${comp.name}/${comp.commit}`;
      }
    }

    // add i18n hosts
    i18nNamespaces[comp.config.app] = host;

    microFrontends.push({
      name: comp.config.app,
      host,
      routers: comp.config.routers,
    });
  });

  config = manifest;
  setData({
    ...data,
    config,
  });

  setMicroFrontends(microFrontends);

  // add app config into config manager
  addConfig('appConfig', config);

  // initialize i18n
  // {
  //   shell: 'http://localhost:2020',
  //   login: 'http://localhost:2006',
  // }
  // i18next configuration: https://www.i18next.com/overview/configuration-options
  initI18n(i18nNamespaces, {
    debug: false,
    lowerCaseLng: false,
    fallbackLng: 'en-US',
    fallbackNS: 'irmo-shell',
    whitelist: false,
    lng, // en-US en-US-sappsd
    load: 'currentOnly',
    defaultNS: 'irmo-shell',
    ns: 'irmo-shell',
    preload: [lng], // en-US en-US-sappsd
    react: {
      useSuspense: false,
      wait: false,
    },
  });

  // Handle error page
  if (window.location.pathname.startsWith('/error')) {
    return setData({
      ...data,
      initializing: false,
    });
  }
};

const renderInitializing = () => {
  return (
    <div
      className="app-loading"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <Spinner cssClass="page-load-spinner" />
    </div>
  );
};

const onFetchAuthSuccess = ({ auth }) => {
  const isLoginPage = window.location.href.includes('/login');
  if (auth.status === 200) {
    window.hasLoggedin = true;
    if (isLoginPage) {
      // history.push('/');
      window.location.href = '/';
    }
  }
};

export const onFetchAuthFailed = ({ error, data, setData }) => {
  window.hasLoggedin = false;
  const isLoginPage = window.location.href.includes('/login');
  if (isLoginPage) {
    console.log(`Auth user error: ${error}`);
    setData({
      ...data,
      initializing: false,
    });
  } else {
    if (error.status === 401) {
      // history.push('/login');
      window.location.href = '/login';
    } else {
      setData({ ...data, authUserError: true });
    }
  }

  // throw new Error(auth.statusText);
};

const onFetchCsrfFailed = ({ error, user, data, setData }) => {
  MessageToast.error('Failed to get CSRF token, please contact System Administrator!');
  console.log(`${error}`);
  // set a fake csrf token
  setCsrfToken('fakecsrftoken');
  setData({
    ...data,
    settings: {},
    user: user.current,
    fetchConfigError: null,
  });
};

export const onFetchSettingsFinally = ({ rawSettings, setData }) => {
  const testingLng = getURLParam(window.location.search, 'sap-language');
  if (testingLng) {
    lng = TestingLocales[testingLng] ? TestingLocales[testingLng] : testingLng;
  }
  // set new theme
  setAppTheme(themeId);

  // set new language
  setLanguage(lng);
  configManagerSetLanguage(lng);
  if (Object.keys(rawSettings.current?.basicSetup) <= 0) {
    setTimeout(() => {
      MessageToast.error('Shell_LoadSettingFailed');
    }, 0);
  }
  // set initialization done
  setData(prevData => ({
    ...prevData,
    initializing: false,
  }));
};

const getDataFromResults = ({ results, index, defValue = {} }) => {
  return Array.isArray(results) && results.length > index && results[index]?.data
    ? results[index]?.data
    : defValue;
};

const onFetchSettingsSuccess = ({ results, rawSettings, data, setData, user }) => {
  updateFeatureToggle(getDataFromResults({ results, index: 4 }).resultList);

  const userProfile = getDataFromResults({ results, index: 1 });
  if (getFeatureToggle(DST_FF)) {
    // If profileTimeZone is null, use UTC-08:00 Pacific Time (US & Canada) -- America/Los_Angeles as default time zone
    userProfile.timeZone = userProfile?.profileTimeZone || 'America/Los_Angeles';
  }

  const companyProfile = getDataFromResults({ results, index: 2 });

  rawSettings.current = {
    ...rawSettings.current,
    basicSetup: getDataFromResults({ results, index: 0 }),
    userProfile,
    companyProfile,
  };
  const settings = mergeSettings(rawSettings.current);

  const currentUser = getDataFromResults({ results, index: 1 });
  addConfig('CurrentUser', currentUser);
  if (getFeatureToggle('RM.20168.Install.Pendo.in.IRM')) {
    //Please move the code of Pendo installation into HEAD tag of index.html once the FF is removed.
    enablePendo(
      { id: currentUser?.id },
      { id: companyProfile?.id, name: companyProfile?.companyName },
    );
  }

  user.current = currentUser;
  addConfig('user', currentUser || {});
  addConfig('ThemeSetting', currentUser?.themeId);

  themeId = currentUser?.themeId;
  if (rawSettings.current.userProfile && rawSettings.current.userProfile.language) {
    lng = rawSettings.current.userProfile.language;
  } else if (rawSettings.current.basicSetup && rawSettings.current.basicSetup.language) {
    lng = rawSettings.current.basicSetup.language;
  }

  user.current.databaseUserId = rawSettings.current.basicSetup.id;

  const currentPermissions = results[3];
  addConfig('CurrentUserPermissions', currentPermissions);
  const isDispositionOnly = isDispositionPermissionOnly(currentPermissions);
  const allowReturnOrderCreate = hasReturnOrderCreate(currentPermissions);
  const allowSettingsWrite = hasSettingsWrite(currentPermissions);
  addConfig('isDispositionOnly', isDispositionOnly);
  if (isDispositionOnly && !history.location.pathname.startsWith(DISPOSITION_HOME_ROUTE)) {
    history.push(DISPOSITION_HOME_ROUTE);
  } else if (
    history.location.pathname === '/rm-customer-service/create-new-return' &&
    !allowReturnOrderCreate
  ) {
    history.replace('/irmo-analytics/home');
  }

  setData({
    ...data,
    settings: { ...settings, allowSettingsWrite },
    user: rawSettings.current.userProfile,
    lang: lng,
  });
};

const App = ({ fetchConfig, fetchAuth, fetchCsrf, fetchSettings }) => {
  const [data, setData] = useState({
    initializing: true,
    fetchConfigError: false,
    fetchSettingsError: false,
    authUserError: false,
    config: null,
    settings: { basicSetup: {}, userProfile: {}, companyProfile: {} },
    user: {},
  });
  const [microFrontends, setMicroFrontends] = useState([]);

  const user = useRef(null);
  const rawSettings = useRef({ basicSetup: {}, userProfile: {}, companyProfile: {} });

  useEffect(() => {
    addConfig('application', 'irmo');
    fetchConfig().then(
      result => {
        const manifest = result.data;
        onFetchConfigSuccess({
          manifest,
          data,
          setData,
          setMicroFrontends,
        });
        fetchAuth().then(
          auth => {
            onFetchAuthSuccess({ auth, user });
            fetchCsrf()
              .then(
                result => setCsrfToken(result?.data?.token),
                error => onFetchCsrfFailed({ error, user, data, setData }),
              )
              .finally(() =>
                fetchSettings()
                  .then(
                    results => {
                      onFetchSettingsSuccess({
                        results,
                        data,
                        setData,
                        rawSettings,
                        user,
                      });
                    },
                    () =>
                      setData({
                        ...data,
                        settings: {},
                        user: user.current,
                        fetchConfigError: false,
                      }),
                  )
                  .finally(() => onFetchSettingsFinally({ rawSettings, data, setData })),
              );
          },
          error => onFetchAuthFailed({ error: error.response, data, setData }),
        );
      },
      error => {
        console.error('Error:', error);
        setData({
          ...data,
          initializing: false,
          fetchConfigError: error,
        });
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    eventBus.on('configuration-updated', message => {
      if (notEmptyStr(message.key)) {
        rawSettings.current[message.key] = message.data;
        const settings = mergeSettings(rawSettings.current);
        if (getFeatureToggle(DST_FF)) {
          if (settings?.profileTimeZone) {
            settings.timeZone = settings.profileTimeZone;
          } else {
            settings.timeZone = 'America/Los_Angeles';
          }
        }

        const updatedUser =
          message.key === 'userProfile'
            ? JSON.parse(JSON.stringify(message.data))
            : {
                ...data.user,
              };
        user.current = updatedUser;

        setData({
          ...data,
          settings,
          user: updatedUser,
        });
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-shadow
    eventBus.on('navigate-page', (path, data) => {
      if (path) {
        history.push(path, data);
      }
    });

    return () => {
      eventBus.die('configuration-updated');
      eventBus.die('navigate-page');
    };
  }, [data]);

  if (data.fetchConfigError) {
    return renderError('Failed to load config, please try again.', data.fetchConfigError);
  }

  if (data.authUserError) {
    return renderError('Failed to get user information, please refresh page.', data.authUserError);
  }

  if (data.fetchSettingsError) {
    return renderError(
      'Failed to get company or user settings, please refresh page.',
      data.fetchSettingsError,
    );
  }

  if (data.initializing) {
    return renderInitializing();
  }

  return renderMfes({ data, user, microFrontends });
};

const renderMfes = ({ data, user, microFrontends }) => {
  const containerRoutes = renderRouteConfigV3(routeConfig, '/', config, data.settings, user);
  const microFrontendRoutes = renderMicroFrontendRoutes({
    mfdRouters: microFrontends,
    history,
    settings: data.settings,
    user,
  });
  return (
    <ThemeProvider>
      <Provider store={store}>
        <Router history={history}>
          <WebAssistant lang={data.lang} />
          <Switch>
            {getConfig('isDispositionOnly') ? (
              <Redirect from="/" to={`${DISPOSITION_HOME_ROUTE}${window.location.search}`} exact />
            ) : (
              <Redirect from="/" to={`/irmo-analytics/home${window.location.search}`} exact />
            )}
            {microFrontendRoutes}
            {containerRoutes}
          </Switch>
        </Router>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
