import React, { memo, useCallback, useEffect } from 'react';
import { ShellBarItem } from '@ui5/webcomponents-react';
import { useSelector } from 'react-redux';
import eureka from 'eureka';

const { useTranslation } = eureka.I18nProvider;

export default memo(() => {
  const { t } = useTranslation();
  const webAssistantLoaded = useSelector(state => state.common.webAssistantLoaded);

  const setShellButtonStyle = useCallback(() => {
    const shellBarButtons = document
      .querySelector('#eureka-shellbar')
      .shadowRoot.querySelectorAll('.ui5-shellbar-button:not(.ui5-shellbar-overflow-button)');
    for (let button of shellBarButtons) {
      button.style.visibility = 'visible';
    }
    const overflowButton = document
      .querySelector('#eureka-shellbar')
      .shadowRoot.querySelector('.ui5-shellbar-overflow-button');
    if (overflowButton) overflowButton.style.display = 'none';
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShellButtonStyle();
    }, 300);
    window.addEventListener('resize', setShellButtonStyle);
    return () => {
      window.removeEventListener('resize', setShellButtonStyle);
    };
  }, [setShellButtonStyle]);

  return (
    <>
      {webAssistantLoaded && (
        <ShellBarItem text={t('Shell_Button_Help')} onClick={null} icon="sys-help" />
      )}
    </>
  );
});
