import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import {
  ShellBar,
  Button,
  Popover,
  PopoverHorizontalAlign,
  PopoverPlacementType,
  StandardListItem,
  FlexBox,
  FlexBoxJustifyContent,
} from '@ui5/webcomponents-react';
import { getAvatar } from '../plugins/profile.plugin';
import logo from './logo';
import LogoutForm from '../plugins/LogoutForm';
import CustomNotification from './CustomNotification';
import WebAssistantHelpButton from '../../common/web-assistant/WebAssistantHelpButton';
import BuiltInSupportButton from '../../common/built-in-support/builtInSupportButton';
import UserProfile from './UserProfile';
import AboutPage from './AboutPage/AboutPage';
import { getConfig, withTranslation, eventBus, Spinner } from 'src/common/eureka';
import { DISPOSITION_HOME_ROUTE } from 'src/common/constants';
import { getFeatureToggle } from 'src/common/eureka';
import { featureToggles } from 'src/common/feature-toggles';
import MoreThanMaxNumberNotifications from './MoreThanMaxNumberNotifications';

const StartButton = ({ slot, onClick }) => (
  <Button slot={slot} icon={'menu2'} design="Transparent" onClick={onClick} />
);
const MAX_NOTIFICATION_DISPLAY_NUMBER = 100;

class Header extends Component {
  static propTypes = {
    common: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
  };

  state = {
    logoutModalVisible: false,
    menuBtnVisible: true,
    isClearing: false,
  };

  notificationPopoverRef = createRef();
  profileMenuRef = createRef();

  componentDidMount() {
    this.props.actions.startWebsocket();
    eventBus.on('toggle-menu-btn-visible', () => {
      this.setState({ menuBtnVisible: !this.state.menuBtnVisible });
    });
  }

  componentWillUnmount() {
    eventBus.off('toggle-menu-btn-visible');
  }

  handleProfileClick = e => {
    this.profileMenuRef.current.showAt(e.detail.targetRef);
  };

  openProfileSetting = () => {
    this.closeProfileMenu();

    let { config, t } = this.props;
    eventBus.emit('dialog-show-hide', '', {
      type: 'user-profile',
      data: {
        showDialog: true,
        title: t('UserProfile_ProfileSettings'),
        content: () => <UserProfile config={config} eventBus={eventBus} />,
      },
    });
  };

  openAboutPage = () => {
    this.closeProfileMenu();

    const { config, t } = this.props;
    eventBus.emit('dialog-show-hide', '', {
      type: 'about-page',
      data: {
        showDialog: true,
        title: t('About'),
        content: () => <AboutPage config={config} eventBus={eventBus} />,
      },
    });
  };

  logout = () => {
    this.closeProfileMenu();
    this.setState({ logoutModalVisible: true });
  };

  closeLogout = () => {
    this.setState({ logoutModalVisible: false });
  };

  closeProfileMenu = () => {
    this.profileMenuRef.current.close();
  };

  handleSidenav = () => {
    const { showMenu } = this.props.common;
    showMenu ? this.props.actions.hideMenu() : this.props.actions.showMenu();
    eventBus.emit('toggle-side-menu');
  };

  handleNotificationClick = e => {
    this.closeProfileMenu();
    this.notificationPopoverRef.current.showAt(e.detail.targetRef);
  };

  dismissNotification = notification => {
    this.props.actions.dismissNotification(notification);
  };

  deleteAllReadNotifications = async notifications => {
    try {
      this.setState({ isClearing: true });
      const notificationIds = notifications.map(notification => notification.id);
      await this.props.actions.deleteAllReadNotifications(notificationIds);
      this.notificationPopoverRef.current.close();
    } catch (e) {
    } finally {
      this.setState({ isClearing: false });
    }
  };

  handleLogoClick = () => {
    if (getConfig('isDispositionOnly')) {
      this.props.history.push(DISPOSITION_HOME_ROUTE);
    } else {
      this.props.history.push('/irmo-analytics/home');
    }
  };

  handleCustomNotificationClick = (e, notification, detailsPageUrl) => {
    if (e?.target?.dataset?.name === 'close-button') {
      return;
    }
    this.notificationPopoverRef.current.close();
    this.props.actions.dismissNotification(notification);
    this.props.history.push(detailsPageUrl);
  };

  render() {
    const built_in_Support_FF = getFeatureToggle(featureToggles.BUILT_IN_SUPPORT.name);

    const { notification } = this.props.common ?? { notification: [] };
    const { t } = this.props;
    const { menuBtnVisible } = this.state;
    let sortedNotifications;
    let hasMoreThanMaxNumberNotifications;
    let hasNotifications;
    let allNotificationCount;
    let remainingNotificationCount;
    sortedNotifications = notification.content ?? [];
    allNotificationCount = notification.totalElements ?? 0;
    hasNotifications = allNotificationCount > 0;
    hasMoreThanMaxNumberNotifications = allNotificationCount > MAX_NOTIFICATION_DISPLAY_NUMBER;
    remainingNotificationCount = allNotificationCount - MAX_NOTIFICATION_DISPLAY_NUMBER;

    return (
      <>
        <ShellBar
          id="eureka-shellbar"
          logo={<img alt="SAP Logo" style={{ maxHeight: '2rem' }} src={logo} />}
          onLogoClick={this.handleLogoClick}
          startButton={menuBtnVisible ? <StartButton onClick={this.handleSidenav} /> : null}
          primaryTitle={t('IRMO')}
          profile={getAvatar(this.props.user)}
          onProfileClick={this.handleProfileClick}
          showNotifications={true}
          notificationsCount={hasNotifications ? `${allNotificationCount}` : ''}
          onNotificationsClick={this.handleNotificationClick}
        >
          <WebAssistantHelpButton />
          {built_in_Support_FF && <BuiltInSupportButton />}
        </ShellBar>

        <Popover
          ref={this.profileMenuRef}
          allowTargetOverlap
          horizontalAlign={PopoverHorizontalAlign.Center}
          placementType={PopoverPlacementType.Bottom}
          preventFocusRestore={false}
        >
          <div>
            <StandardListItem
              data-key={'ProfileSettings'}
              onClick={this.openProfileSetting}
              icon="user-settings"
              infoState="None"
              selected={false}
              type="Active"
            >
              {t('ProfileSettings')}
            </StandardListItem>
            <StandardListItem
              data-key={'About'}
              onClick={this.openAboutPage}
              infoState="None"
              icon={'message-information'}
              selected={false}
              type="Active"
            >
              {t('About')}
            </StandardListItem>
            <StandardListItem
              data-key={'Logout'}
              onClick={this.logout}
              infoState="None"
              icon={'log'}
              selected={false}
              type="Active"
            >
              {t('Logout')}
            </StandardListItem>
          </div>
        </Popover>

        <Popover
          ref={this.notificationPopoverRef}
          allowTargetOverlap
          horizontalAlign={PopoverHorizontalAlign.Center}
          placementType={PopoverPlacementType.Bottom}
        >
          {hasNotifications ? (
            <div
              style={{
                width: '460px',
                padding: ' 0 0 0 1px',
                maxHeight: '400px',
                overflow: 'auto',
                overflowX: 'hidden',
                paddingBottom: '10px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  whiteSpace: 'nowrap',
                }}
              >
                {sortedNotifications.slice(0, MAX_NOTIFICATION_DISPLAY_NUMBER).map(notification => {
                  const style = {
                    width: '100%',
                    display: 'inline-block',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  };

                  if (notification?.acknowledged) {
                    Object.assign(style, {
                      color: '#bbbbbb',
                    });
                  }

                  return (
                    <CustomNotification
                      key={'notification_' + notification.id}
                      notification={notification}
                      onCloseFunction={this.dismissNotification}
                      onClickFunction={this.handleCustomNotificationClick}
                      settings={this.props.settings}
                    />
                  );
                })}
              </div>
              <div>
                {hasMoreThanMaxNumberNotifications && (
                  <MoreThanMaxNumberNotifications
                    remainingNotifications={remainingNotificationCount}
                  />
                )}
              </div>
              <div>
                <hr />
                <FlexBox justifyContent={FlexBoxJustifyContent.End}>
                  {this.state.isClearing ? (
                    <Spinner />
                  ) : (
                    <Button onClick={() => this.deleteAllReadNotifications(sortedNotifications)}>
                      {t('Header_Notification_Clear_All_Notifications')}
                    </Button>
                  )}
                </FlexBox>
              </div>
            </div>
          ) : (
            <div>{t('Header_Notification_Empty')}</div>
          )}
        </Popover>

        <LogoutForm
          eventBus={eventBus}
          open={this.state.logoutModalVisible}
          closeLogout={this.closeLogout}
        />
      </>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Header));
