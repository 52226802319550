import {
  COMMON_GET_NOTIFICATIONS_SUCCESS,
  COMMON_GET_NOTIFICATIONS_FAILED,
  ADD_NEW_NOTIFICATION,
} from './constants';
import HeartBeatWebSocket from './HeartBeatWebSocket';
import {
  fetchNotifications,
  dismissNotificationRequest,
  dismissAllNotificationsRequest,
  deleteNotificationRequest,
  deleteAllReadNotificationsRequest,
} from '../NotificationAxios';
import { eventBus } from 'src/common/eureka';

let __dispatch = null;
let __websocket = null;

function onMessage(jsonData) {
  fetchNotificationAction();

  if (jsonData.data.businessObject === 'RETURN_DETAIL_UPDATE') {
    eventBus.emit('on-return-update', '', jsonData.data);
  } else if (__dispatch) {
    if (jsonData) {
      __dispatch({ type: ADD_NEW_NOTIFICATION, jsonData });
    } else {
      fetchNotificationAction();
    }
  }
}

function fetchNotificationAction() {
  fetchNotifications().then(
    res => {
      __dispatch({
        type: COMMON_GET_NOTIFICATIONS_SUCCESS,
        data: (res && res.data) || [],
      });
      return res;
    },
    err => {
      __dispatch({
        type: COMMON_GET_NOTIFICATIONS_FAILED,
        data: err,
      });
      return err;
    },
  );
}

export function startWebsocket() {
  return dispatch => {
    if (__dispatch) {
      return;
    }
    __dispatch = dispatch;
    if (!__websocket) {
      let protocol = window.location.protocol.startsWith('https') ? 'wss' : 'ws';
      let url = `${protocol}://${window.location.host}/api/rm-notification-push/`;
      __websocket = new HeartBeatWebSocket(url, onMessage, fetchNotificationAction).start();
    }

    // listen close websocket of eventbus
    eventBus.on('close-websocket', evtBody => {
      console.log(evtBody);
      __websocket && __websocket.close();
    });
  };
}

export function dismissNotification(notification) {
  return () =>
    dismissNotificationRequest(notification).finally(() => {
      fetchNotificationAction();
    });
}

export function dismissAllNotifications(notifications) {
  return () =>
    dismissAllNotificationsRequest(notifications).finally(() => {
      fetchNotificationAction();
    });
}

export function deleteNotification() {
  return () =>
    deleteNotificationRequest().finally(() => {
      fetchNotificationAction();
    });
}

export function deleteAllReadNotifications(notificationIds) {
  return () =>
    deleteAllReadNotificationsRequest(notificationIds).finally(() => {
      fetchNotificationAction();
    });
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notification: action.data,
      };
    case COMMON_GET_NOTIFICATIONS_FAILED:
      return {
        ...state,
        notification: [],
      };
    case ADD_NEW_NOTIFICATION:
      return { ...state, notification: [...state.notification, action.jsonData] };
    default:
      return state;
  }
}
