import React from 'react';
import eureka from 'eureka';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchAppConfig } from '../common/redux/actions';
import * as actions from './redux/actions';
const { useTranslation } = eureka.I18nProvider;

function Tiles(props) {
  const { t } = useTranslation();

  const renderTiles = () => {
    const config = props.config;
    let entrypoints = [];
    if (config && config.components) {
      config.components.forEach(comp => {
        if (comp.config.entrypoints) {
          entrypoints = entrypoints.concat(comp.config.entrypoints);
        }
      });
    }

    const tiles = entrypoints.map((entry, idx) => (
      <div
        className="fd-panel icon-tile"
        key={idx.toString()}
        onClick={() => {
          if (entry.router) {
            props.history.push(entry.router);
          } else if (entry.url) {
            const newwin = window.open(
              entry.url,
              '_blank',
              'noopener,noreferrer,resizable,scrollbars',
            );
            newwin.opener = null;
          }
        }}
      >
        <div className="fd-panel__header">
          <div className="fd-panel__head">
            <h3 className="fd-panel__title">{t(entry.title)}</h3>
          </div>
        </div>
        <div className="fd-panel__body no-padding">
          <p className="tile-text">{t(entry.text)}</p>
          <span
            className={
              entry.icon
                ? 'sap-icon ' + entry.icon + ' sap-icon--xl'
                : `sap-icon sap-icon--chain-link sap-icon--xl`
            }
          ></span>
        </div>
      </div>
    ));
    return <div className="fd-panel-grid fd-panel-grid--4col">{tiles}</div>;
  };

  return (
    <React.Fragment>
      <section className="fd-section" style={{ maxWidth: '1100px' }}>
        {renderTiles()}
      </section>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions, fetchAppConfig }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Tiles);
