import React from 'react';
import { useTranslation } from '../../common/eureka';
import { Title, TitleLevel } from '@ui5/webcomponents-react';
import { createUseStyles } from 'react-jss';
import { ThemingParameters } from '@ui5/webcomponents-react-base';
import { SupportItem } from './SupportItem';

const styles = () => ({
  supportHeader: {
    color: ThemingParameters.sapNeutralTextColor,
    marginTop: '12px',
    marginBottom: '12px',
    paddingLeft: '2rem',
  },
  supportPage: {
    backgroundColor: ThemingParameters.sapBackgroundColor,
    paddingTop: '1rem',
    paddingLeft: '2rem',
    height: '100%',
  },
});
const useStyles = createUseStyles(styles);

const SupportPageInfo = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const supportItems = [
    {
      title: t('Subscribed_Customers_Title'),
      message: t('Subscribed_Customers_Message'),
      linkDescription: t('Subscribed_Customers_Link_Description'),
      link: 'https://launchpad.support.sap.com/#incident/create',
    },
    {
      title: t('Product_Information_Title'),
      message: t('Product_Information_Message'),
      linkDescription: t('Product_Information_Link_Description'),
      link: 'https://help.sap.com/returns',
    },
  ];

  return (
    <main className="fd-app__main">
      <Title level={TitleLevel.H5} className={classes.supportHeader}>
        {t('Support_Page_Title')}
      </Title>
      <div className={classes.supportPage}>
        {supportItems.map((item, idx) => {
          return <SupportItem key={idx} supportItem={item} />;
        })}
      </div>
    </main>
  );
};

export default SupportPageInfo;
