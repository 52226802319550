import { useRef } from 'react';
import { MessageBox, Button, ButtonDesign } from '@ui5/webcomponents-react';
import { axiosCreate, getConfig, useTranslation } from 'src/common/eureka';

const axios = axiosCreate().instance;

export default function LogoutForm({ eventBus, open, closeLogout }) {
  const { t } = useTranslation();
  const formRef = useRef();
  const { auth_provider, auth_method } = getConfig('user') || {};

  return (
    <MessageBox
      open={open}
      titleText={t('Logout_Confirmation_Title')}
      actions={[
        <Button
          key="rm-signout"
          data-testid="rm-signout"
          design={ButtonDesign.Emphasized}
          onClick={() => {
            if (formRef.current) {
              axios.get('/auth/user').then(() => {
                eventBus.emit('close-websocket', '', {
                  type: 'logout',
                  data: {},
                });

                setTimeout(() => {
                  formRef.current.submit();
                }, 2000);
              });
            }
          }}
        >
          {t('Logout')}
        </Button>,
        <Button>{t('Cancel')}</Button>
      ]}
      onClose={closeLogout}
    >
      <div>
        <form ref={formRef} action="/auth/logout" method="post">
          <input type="hidden" name="auth_provider" value={auth_provider} />
          <input type="hidden" name="auth_method" value={auth_method} />
        </form>
        {t('Logout_Confirmation_Msg')}
      </div>
    </MessageBox>
  );
}
