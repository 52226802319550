export const devUrl =
  'https://webassistant.enable-now.cloud.sap/wa_cfg/sap_intelligent_returns_management_internal_content_production/WebAssistant.js';

export const prodUrl =
  'https://webassistant.enable-now.cloud.sap/wa_cfg/sap_intelligent_returns_management_productive/WebAssistant.js';

export const getWebAssistantUrl = () => {
  switch (window.location.hostname) {
    case 'localhost':
    case 'irmo.rm-dev3.rm-dev.eurekacloud.io':
      return devUrl;
    default:
      return prodUrl;
  }
};
