export function getRandom(digit = 5) {
  return (Math.random() + '').slice(2, digit + 2);
}

export const buildSideNavListItems = ({ initListItems, components, getFeatureToggle }) => {
  if (!Array.isArray(components)) {
    return initListItems;
  }

  const listItems = components.reduce(
    (acc, component) => {
      const sidenav = component.config.sidenav;
      if (!(sidenav && Array.isArray(sidenav))) {
        return acc;
      }
      sidenav.forEach(sidenavItem => {
        if (sidenavItem.id !== 'returns' && sidenavItem.id !== 'settings') {
          return;
        }
        sidenavItem.items = sidenavItem.items.filter(tabItem => {
          if (!tabItem.featureToggle) {
            return true;
          }

          return getFeatureToggle(tabItem.featureToggle);
        });
      });

      return acc.concat(sidenav);
    },
    [...initListItems],
  );

  return listItems;
};
