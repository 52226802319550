import { useState, useEffect, useRef } from 'react';
import history from '../history';
import { eventBus } from '../eureka';
import { getWebAssistantUrl } from './waConfig';

const initialSearchParams = new URLSearchParams(window.location.search);

const allowList = [
  'help-serviceLayerVersion',
  'help-dataUrlWPB2',
  'help-autoStartTour',
  'help-editor',
];

const areSearchParamsInAllowList = searchParams => {
  return Array.from(searchParams.keys()).some(key => allowList.includes(key));
};

const waLanguageCodeMapping = {
  'no-NO': 'nb-NO',
};

const useWebAssistant = ({ lang }) => {
  const [webAssistantLoaded, setWebAssistantLoaded] = useState(false);
  const prevPathname = useRef('');

  useEffect(() => {
    const unlisten = history.listen(({ pathname, search, state }) => {
      if (prevPathname.current === pathname) {
        // prevent execute itself multiple times
        return;
      }
      prevPathname.current = pathname;

      if (initialSearchParams.toString() && areSearchParamsInAllowList(initialSearchParams)) {
        const params = new URLSearchParams(search);
        for (const [key, value] of initialSearchParams) {
          if (allowList.includes(key) && !params.has(key)) {
            params.append(key, value);
          }
        }

        eventBus.emit('ignore-router-change', '', true);
        history.replace(`${pathname}?${params}`, state);
        eventBus.emit('ignore-router-change', '', false); // prevent show prompt twice
      }

      window.onPathChange && window.onPathChange(pathname);
    });
    return unlisten;
  }, []);

  useEffect(() => {
    document.documentElement.lang = waLanguageCodeMapping[lang] || lang;
  }, [lang]);

  useEffect(() => {
    const scriptId = 'web-assistant-script';
    if (document.getElementById(scriptId)) return;
    const script = document.createElement('script');

    script.id = scriptId;
    script.src = getWebAssistantUrl();
    script.defer = 'defer';

    script.onload = () => {
      setWebAssistantLoaded(true);
    };
    document.body.appendChild(script);
  }, []);

  return { webAssistantLoaded };
};

export default useWebAssistant;
