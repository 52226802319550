import React from 'react';
import { List, ListMode, CustomListItem, Icon } from '@ui5/webcomponents-react';
import { getThemeId, getThemeList } from 'src/common/eureka';
import { ThemingParameters } from '@ui5/webcomponents-react-base';

const formItemStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

export default function UserAppearance({ onDataPropChange }) {
  const [currentTheme, setCurrentTheme] = React.useState(getThemeId());

  return (
    <div style={{ ...formItemStyle }}>
      <List
        id="theme-setting"
        data-testid="select-theme-id"
        value={getThemeId()}
        mode={ListMode.SingleSelect}
        onSelectionChange={evt => {
          /* istanbul ignore next */
          const themeId = evt.detail?.selectedItems[0]?.dataset.id;
          console.log(`Theme Id is ${themeId}`);
          setCurrentTheme(themeId);
          onDataPropChange(themeId, 'themeId');
        }}
        style={{ width: '100%', height: '330px' }}
      >
        {getThemeList().map((theme, i) => {
          return (
            <CustomListItem
              key={theme.themeId + '-option'}
              data-id={theme.themeId}
              value={theme.themeId}
              selected={getThemeId() === theme.themeId}
              style={{ width: '100%', height: '68px', paddingRight: '10px' }}
              tooltip={theme.displayName}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '85px' }}>
                  <div
                    style={{
                      ...theme.style,
                      width: '2.5rem',
                      height: '2.5rem',
                      borderRadius: '100%',
                      margin: 'auto',
                    }}
                  ></div>
                </div>
                <div
                  data-testid={'select-theme-id-' + i}
                  style={{
                    width: '260px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    lineHeight: '68px',
                    color: ThemingParameters.sapTextColor,
                    paddingRight: '5px',
                  }}
                >
                  {theme.displayName}
                </div>
                {currentTheme === theme.themeId && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '16px',
                      height: '16px',
                      borderRadius: '50%',
                      background: ThemingParameters.sapSelectedColor,
                      margin: '0 10px',
                    }}
                  >
                    <Icon
                      name="accept"
                      style={{
                        width: '12px',
                        height: '12px',
                        color: ThemingParameters.sapContent_ForegroundColor,
                      }}
                    />
                  </div>
                )}
              </div>
            </CustomListItem>
          );
        })}
      </List>
    </div>
  );
}
