import { languagesAxios, setupsAxios, usersAxios } from '../axios';

export const fetchMe = async () => usersAxios.get('/me');

export const patchMe = async payload => usersAxios.patch('/me', payload);

export const fetchBasicSetup = async () => setupsAxios.get('/basic-setup');

export const fetchLanguages = async currentLang => languagesAxios.get(`/${currentLang}`);

export const fetchTimezones = async () => await (await usersAxios.get('/irm/timezones')).data;
