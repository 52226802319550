const getSdkHelpers = () => {
  let sdk;
  const getSdk = () => {
    return sdk;
  };
  const setSdk = loadedSdk => {
    sdk = loadedSdk;
  };

  return {
    getSdk,
    setSdk,
  };
};

const SdkHelpers = getSdkHelpers();

export default SdkHelpers;
