import React from 'react';
import { AvatarShape, AvatarSize } from '@ui5/webcomponents-react';
import MyAvatar from './MyAvatar';

export const getAvatar = user => {
  const avatar = user?.avatarIcon || {};
  const type = avatar.type?.toLocaleLowerCase();
  const value = avatar.value;
  return (
    <MyAvatar
      initials={type === 'initials' ? value : ''}
      image={type === 'image' ? value : null}
      icon={type === 'genderneutralicon' ? 'employee' : ''}
      shape={AvatarShape.Circle}
      size={AvatarSize.XS}
      type={type}
      value={value}
    />
  );
};
