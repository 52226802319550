// import throttle from 'lodash.throttle';
// import { SCREEN_L_SIZE } from './constants';
import { MessageToast, i18n } from 'src/common/eureka';

// const handleResize = throttle(() => {
//   if (window.innerWidth >= SCREEN_L_SIZE) {
//     document.body.classList.add('ui5-content-density-compact');
//   } else {
//     document.body.classList.remove('ui5-content-density-compact');
//   }
// }, 300);
// handleResize();

// window.addEventListener('resize', handleResize);

window.addEventListener('unhandledrejection', event => {
  const error = event.reason;
  let status = error?.response?.status;
  if (
    /^[4-5]\d{2}/.test(status) &&
    status !== 401 &&
    status !== 403 &&
    !error.noGlobalNotification
  ) {
    MessageToast.error(error?.response?.data?.message || i18n.t('CommonUnknownRequestError'));
  }
});

export const enablePendo = (userInfo, accountInfo) => {
  const install = () => {
    const API_KEY = '435f8aa2-325e-4a67-7a41-b8a5f064b228';
    (function (apiKey) {
      (function (p, e, n, d, o) {
        var v, w, x, y, z;
        o = p[d] = p[d] || {};
        o._q = o._q || [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
        for (w = 0, x = v.length; w < x; ++w)
          (function (m) {
            o[m] =
              o[m] ||
              function () {
                o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
              };
          })(v[w]);
        y = e.createElement(n);
        y.async = !0;
        y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
        z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
      })(window, document, 'script', 'pendo');
    })(API_KEY);
  };

  const init = () => {
    window.pendo.initialize({
      visitor: userInfo,
      account: accountInfo,
    });
  };

  install();
  init();
};
