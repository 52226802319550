import { getRandom } from '../features/common/Utils';
import { getThemeList } from './eureka';

export const loadCSS = (dom, assetName, host, name, filePath, hash) => {
  // load css styles
  const buildCssLink = (resolve, reject) => {
    const styleId = `${assetName}-style-${name.toLowerCase()}`;
    const style = dom.getElementById(styleId);
    if (!style) {
      const cssLink = dom.createElement('link');
      cssLink.id = styleId;
      cssLink.rel = 'stylesheet';
      cssLink.type = 'text/css';
      cssLink.crossOrigin = 'anonymous';
      cssLink.href = `${host}${filePath}?random=${getRandom()}`;
      if (hash) {
        cssLink.integrity = hash;
      }
      cssLink.onload = () => resolve();
      cssLink.onerror = err => reject(err);
      dom.head.appendChild(cssLink);
    } else {
      resolve();
    }
  };

  return new Promise((resolve, reject) => {
    buildCssLink(resolve, reject);
  });
};

export function getURLParam(url, name) {
  const searchParams = new URLSearchParams(url);
  return searchParams.get(name);
}

// 2Q = en-US-sappsd;
export const TestingLocales = {
  '2Q': 'en-US-sappsd',
};

/**
 * function to remove a MFE Component from Side-nav list if FF is disabled
 * @param {Array}: input array containing list of components/MFEs
 * @param {String}: name of components/MFE to be shown/hidden
 * @param {Boolean}: feature flag
 * @returns {Array}: output array containing list of components/MFEs to be displayed in side-nav
 * */
export function checkAndFilterDisabledComponent(componentList, componentName, isEnabled = false) {
  if (!componentName || isEnabled) {
    return componentList;
  }

  // if FF is disabled, remove component from the list and return remaining list
  return componentList.filter(({ id }) => id !== componentName);
}

/**
 * Check whether the user only has the disposition permissions.
 * @param {Array} currentPermissions, this array doesn't have the duplicated values
 * @returns {Boolean} true when user only has the disposition permissions. Otherwise, false.
 */
export const isDispositionPermissionOnly = currentPermissions => {
  return currentPermissions.includes('DISPRET.READ') && !currentPermissions.includes('MNGRET.READ');
};

export const hasReturnOrderCreate = currentPermissions =>
  currentPermissions.includes('RETURNOBJECT.CREATE');

export const hasSettingsWrite = currentPermissions => currentPermissions.includes('SETTINGS.WRITE');

export const getThemeById = themeId => {
  return (getThemeList() || []).find(ele => ele.themeId === themeId);
};
