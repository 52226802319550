import { axiosCreate } from '../../common/eureka';

export const usersAxios = axiosCreate({
  baseURL: '/api/user-configuration-plus/business/v1/users',
}).instance;

export const setupsAxios = axiosCreate({
  baseURL: '/api/rm-company-management/business/v1/setups',
}).instance;

export const companiesAxios = axiosCreate({
  baseURL: '/api/rm-company-management/business/v1/companies',
}).instance;

export const languagesAxios = axiosCreate({
  baseURL: '/api/rm-company-management/business/v1/languages/current-language-setting',
}).instance;
