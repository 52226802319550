import { axiosCreate, getConfig } from '../../common/eureka';

const injectInterceptor = axiosInstance => {
  axiosInstance.interceptors.request.use(config => {
    const { tenantId, id } = getConfig('user') || {};
    config.headers['X-Tenant-ID'] = tenantId;
    config.headers['X-User-ID'] = id;
    return config;
  });
  return axiosInstance;
};

const notificationAxios = injectInterceptor(
  axiosCreate({
    baseURL: `/api/rm-notification-push/v1/messages`,
    timeout: 30000,
  }).instance,
);

export const dismissNotificationRequest = notification => {
  return notificationAxios.post(`/${notification.id}`);
};

export const fetchNotifications = (pageSize = 100, pageNumber = 1) => {
  if (!getConfig('user')) return Promise.reject();

  return notificationAxios.get(`/search?pageNumber=${pageNumber}&pageSize=${pageSize}`);
};

export const dismissAllNotificationsRequest = notifications => {
  const body = notifications.map(notification => {
    return Object.assign({}, notification, {
      acknowledged: true,
    });
  });
  return notificationAxios.post('/', body);
};

export const deleteNotificationRequest = notification =>
  notificationAxios.delete(`/${notification.id}`);

export const deleteAllReadNotificationsRequest = notificationIds => {
  return notificationAxios.post(`/batch-ack`, notificationIds);
};
