import React, { useRef, useState } from 'react';
import {
  NotificationListItem,
  Priority,
  Link,
  FlexBox,
  Avatar,
  Icon,
  Label,
  Button,
  NotificationAction,
} from '@ui5/webcomponents-react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'src/common/eureka';
import moment from 'moment';
import { WrappingType } from '@ui5/webcomponents-react/dist/enums';
import { useResizeObserver } from './hooks/useResizeObserver';
// import moment from 'moment';
/**
 *
 *
 Range  Key  Sample Output
 0 to 44 seconds  s  a few seconds ago
 unset  ss  44 seconds ago
 45 to 89 seconds  m  a minute ago
 90 seconds to 44 minutes  mm  2 minutes ago ... 44 minutes ago
 45 to 89 minutes  h  an hour ago
 90 minutes to 21 hours  hh  2 hours ago ... 21 hours ago
 22 to 35 hours  d  a day ago
 36 hours to 25 days  dd  2 days ago ... 25 days ago
 26 to 45 days  M  a month ago
 45 to 319 days  MM  2 months ago ... 10 months ago
 320 to 547 days (1.5 years)  y  a year ago
 548 days+  yy  2 years ago ... 20 years ago
 *
 */
// const Ranges = [/a few seconds ago/gi, /44 seconds ago/gi];

// const Second = 1000;
// const Minute = 60 * Second;
// const Hour = 60 * Minute;
// const Day = 24 * Hour;
// const Year = 365 * Day;

const styles = () => ({
  notificationStyle: {
    width: '440px',
    position: 'relative',
    marginTop: '4px',
    marginBottom: '4px',
    borderRadius: '0.25rem',
    boxShadow: 'var(--sapContent_Shadow0)',
    '& > div > div': {
      boxShadow: 'none',
    },
  },
  contentWrapper: ({ showMore }) => ({
    marginBottom: '12px',
    display: '-webkit-box',
    lineClamp: showMore ? 'unset' : '2',
    boxOrient: 'vertical',
    overflow: 'hidden',
  }),
  content: {
    whiteSpace: 'normal',
    color: '#4D4D4D',
    marginTop: '12px',
  },
  footer: {
    textAlign: 'right',
  },
  datetime: {
    marginRight: '1rem',
    color: '#6A6D70',
  },
  closeButton: {
    position: 'absolute',
    top: '1rem',
    right: '14px',
  },
});

const isTextClamped = element => element?.scrollHeight > element?.clientHeight;

export default function CustomNotification({
  notification,
  onCloseFunction,
  onClickFunction,
  settings,
}) {
  const { t } = useTranslation();

  const {
    data: { businessObject, message },
  } = notification;

  const receivedDateTime = moment(notification.receivedTime, 'YYYY-MM-DDTHH:mm:ss.SSSZZ');
  const differenceTime = moment().diff(receivedDateTime);
  const duration = moment.duration(differenceTime);
  const daysDiff = duration.days();
  const hoursDiff = duration.hours();
  const minutesDiff = duration.minutes();

  const contentRef = useRef(null);
  const [isClamped, setIsClamped] = useState(false);
  const [showMore, setShowMore] = useState(false);

  useResizeObserver(contentRef, undefined, () => {
    setIsClamped(isTextClamped(contentRef.current));
  });

  const useStyles = createUseStyles(styles);
  const classes = useStyles({ showMore });

  let fromNow = '';
  if (daysDiff > 3) {
    fromNow = receivedDateTime.format(settings?.dateFormat || 'DD.MM.YYYY');
  } else if (daysDiff > 0) {
    fromNow = t('Measure_Day', { count: daysDiff });
  } else if (hoursDiff > 0) {
    fromNow = t('Measure_Hour', { count: hoursDiff });
  } else if (minutesDiff > 0) {
    fromNow = t('Measure_Minute', { count: minutesDiff });
  }
  if (fromNow) {
    fromNow += daysDiff > 3 ? '' : ' ' + t('Measure_Suffix_Ago');
  } else {
    fromNow = t('Measure_Just_Now');
  }

  let assignmentHeader,
    assignorFirstName,
    assignorLastName,
    assignmentText,
    comment,
    priority,
    detailsPageUrl;

  try {
    ({
      detailsPageUrl,
      assignorFirstName,
      assignorLastName,
      assignmentText,
      comment,
      assignmentHeader,
      priority,
    } = JSON.parse(message));
    if (businessObject === 'ASSIGN_USER' || businessObject === 'UNASSIGN_USER') {
      assignmentHeader = t('Header_Notification_Assignment_Title');
    }
  } catch {
    assignmentText = message;
  }

  const showAvatar = !!assignorFirstName && !!assignorLastName;
  const fullName = `${assignorFirstName || ''} ${assignorLastName || ''}`.trim();

  let notificationPriority = Priority.Medium;
  if (priority === 'POSITIVE') {
    notificationPriority = Priority.Low;
  } else if (priority === 'NEGATIVE') {
    notificationPriority = Priority.High;
  }

  return (
    <NotificationListItem
      onClick={e => {
        onClickFunction(e, notification, detailsPageUrl);
      }}
      className={classes.notificationStyle}
      titleText={assignmentHeader || businessObject}
      priority={notificationPriority}
      footnotes={
        <FlexBox>
          {fullName && (
            <>
              <Label>{fullName}</Label>
              <div style={{ marginInline: '0.5rem' }}>·</div>
            </>
          )}
          <Label className={classes.datetime}>{fromNow}</Label>
          {(isClamped || showMore) && (
            <Link
              data-testid="show-more-less-button"
              onClick={e => {
                e.stopPropagation();
                setShowMore(!showMore);
              }}
            >
              {t(showMore ? 'Header_Notification_Show_Less' : 'Header_Notification_Show_More')}
            </Link>
          )}
        </FlexBox>
      }
      avatar={
        showAvatar ? (
          <Avatar
            backgroundColor="Accent6"
            initials={`${assignorFirstName?.charAt(0)}${assignorLastName?.charAt(0)}`.toUpperCase()}
            shape="Circle"
            size="XS"
            tooltip={`${assignorFirstName} ${assignorLastName}`}
          />
        ) : undefined
      }
      actions={<NotificationAction text="" disabled />}
      wrappingType={WrappingType.Normal}
      read={false}
    >
      {notificationPriority === Priority.Medium && (
        <Icon
          name="message-warning"
          style={{
            position: 'absolute',
            top: '1rem',
            left: showAvatar ? '3.75rem' : '1.75rem',
            backgroundColor: '#fff',
            color:
              priority === 'CRITICAL'
                ? 'var(--sapNegativeElementColor)'
                : 'var(--sapCriticalElementColor)',
          }}
        />
      )}
      <div className={classes.contentWrapper} ref={contentRef}>
        <div className={classes.content}>{assignmentText}</div>
        <div className={classes.content}>{comment}</div>
        <Button
          data-name="close-button"
          design="Transparent"
          icon="decline"
          className={classes.closeButton}
          onClick={() => onCloseFunction(notification)}
        />
      </div>
    </NotificationListItem>
  );
}
