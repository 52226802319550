import React, { Suspense } from 'react';
import { useTranslation, Spinner } from '../../common/eureka';
import logo from './ErrorBear';
import { Header, SidePanel } from '../common';
import { createUseStyles } from 'react-jss';

const styles = () => ({
  errorPage: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorTitle: {
    fontWeight: 'normal',
    fontSize: '144px',
    lineHeight: '166px',
    color: '#a7a7a7',
    marginBottom: '17px',
    marginTop: '-1rem',
  },
  errorContent: {
    fontSize: '25px',
    lineHeight: '29px',
    color: '#a7a7a7',
  },
  dashboard: {
    color: '#0854A0',
    cursor: 'pointer',
  },
  errorMsg: {
    fontSize: '42px',
    fontWeight: 'bold',
    lineHeight: '48px',
    color: '#fff',
    margin: 0,
  },
  errorMsgWrapper: {
    position: 'absolute',
    top: '225px',
    left: '145px',
    textAlign: 'center',
  },
});
const useStyles = createUseStyles(styles);

const PageNotFound = props => {
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <div id="eureka-shell-container" className="fd-shell fd-shell--fundamentals">
      <div className="shell-header">
        <Suspense fallback={<Spinner />}>
          <Header
            history={props.history}
            config={props.config}
            settings={props.settings}
            eventBus={props.eventBus}
            user={props.user}
          />
        </Suspense>
      </div>
      <div className="fd-shell__app">
        <div className="fd-app">
          <div>
            <Suspense fallback={<Spinner />}>
              <SidePanel history={props.history} match={props.match} config={props.config} />
            </Suspense>
          </div>
          <main className="fd-app__main">
            <div className={classes.errorPage}>
              <div
                className="errorpage-wrapper"
                style={{ marginRight: '122px', height: '259px', textAlign: 'center' }}
              >
                <h1 className={classes.errorTitle}>{t('ErrorTips_Ohno')}</h1>
                <p className={classes.errorContent}>{t('ErrorTips_404')}</p>
                <p className={classes.errorContent}>
                  {t('ErrorTips_back')}
                  <span className={classes.dashboard} onClick={() => props.history.push('/')}>
                    {t('ErrorTips_dashboard')}.
                  </span>
                </p>
              </div>
              <div className="img-wrapper" style={{ position: 'relative', height: '545px' }}>
                <img alt="Error Logo" src={logo} width={452} height={545} />
                <div className={classes.errorMsgWrapper}>
                  <p className={classes.errorMsg}>404</p>
                  <p style={{ fontSize: '20px', color: '#fff', fontWeight: 'bold', margin: 0 }}>
                    {t('ErrorCode_error')}
                  </p>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
