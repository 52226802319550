import { useEffect, memo } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../features/common/redux/actions';
import useWebAssistant from './useWebAssistant';

const WebAssistant = memo(props => {
  const { lang } = props;
  const { setWebAssistantLoaded } = props.actions;
  const { webAssistantLoaded } = useWebAssistant({ lang });
  useEffect(() => {
    if (webAssistantLoaded) {
      setWebAssistantLoaded();
    }
  }, [webAssistantLoaded, setWebAssistantLoaded]);
  return null;
});

/* istanbul ignore next */
function mapStateToProps() {
  return {};
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WebAssistant);
