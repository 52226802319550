import { useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

/**
 * Observes changes in the size of an element using the `ResizeObserver` API.
 */
export const useResizeObserver = (ref, params, callback) => {
  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      callback({
        contentRect: entries[0].contentRect,
        disconnect: () => resizeObserver.disconnect(),
      });
    });

    resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);
};
