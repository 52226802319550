const loadSdkScript = async (src, async = true, type = 'text/javascript') => {
  return await new Promise((resolve, reject) => {
    try {
      const el = document.createElement('script');
      el.src = src;
      el.async = async;
      el.type = type;
      const container = document.head || document.body;
      el.addEventListener('load', () => {
        resolve({ sdk: window['sdk-react-dsx-everywhere'] });
      });
      el.addEventListener('error', () => {
        reject(new Error(`Failed to load the script ${src}`));
      });
      container.appendChild(el);
    } catch (error) {
      reject(error);
    }
  });
};

export default loadSdkScript;
