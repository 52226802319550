import React from 'react';
import {
  SegmentedButton,
  SegmentedButtonItem,
  Select,
  Option,
  Label,
} from '@ui5/webcomponents-react';
import { DateFormatOptions, TimeFormatOptions, TimeFormatTexts } from './options';
import { useTranslation } from '../../../common/eureka';

const formItemStyle = {
  width: '100%',
  margin: '15px auto',
  display: 'flex',
  flexDirection: 'column',
};

const labelStyle = {
  width: '100%',
  marginBottom: '4px',
};

export default function LanguageAndRegion({ data, languages, onDataPropChange }) {
  const { t } = useTranslation();
  return (
    <div style={{ padding: '0 12px' }}>
      <div style={{ ...formItemStyle }}>
        <Label showColon={true} style={{ ...labelStyle }}>
          {t('UserProfile_Language')}
        </Label>
        <Select
          value={data.language}
          onChange={({ detail }) => {
            const lng = detail.selectedOption.dataset.id;
            onDataPropChange(lng, 'language');
          }}
          data-testid="language"
        >
          {languages?.map(({ displayLanguageCode, displayLanguage, language }) => {
            return (
              <Option
                key={displayLanguageCode}
                data-id={displayLanguageCode}
                value={displayLanguageCode}
                selected={displayLanguageCode === data.language}
              >
                {`${displayLanguage.nativeLanguage} (${language})`}
              </Option>
            );
          })}
        </Select>
      </div>
      <div style={{ ...formItemStyle }}>
        <Label showColon={true} style={{ ...labelStyle }}>
          {t('UserProfile_DateFormat')}
        </Label>
        <Select
          value={data.dateFormat}
          onChange={evt => {
            onDataPropChange(evt.detail.selectedOption.dataset.id, 'dateFormat');
          }}
          id="dateFormat"
          data-testid="rm-dateFormat"
        >
          {DateFormatOptions.map((dateFormat, index) => {
            return (
              <Option
                key={dateFormat}
                data-id={dateFormat}
                value={dateFormat}
                selected={dateFormat === data.dateFormat}
              >
                {DateFormatOptions[index]}
              </Option>
            );
          })}
        </Select>
      </div>
      <div style={{ ...formItemStyle }}>
        <Label showColon={true} style={{ ...labelStyle }}>
          {t('UserProfile_TimeFormat')}
        </Label>
        <SegmentedButton>
          {TimeFormatOptions.map((timeFormat, index) => (
            <SegmentedButtonItem
              key={timeFormat}
              id={timeFormat}
              onClick={() => onDataPropChange(timeFormat, 'timeFormat')}
              pressed={timeFormat === data.timeFormat}
            >
              {t(TimeFormatTexts[index])}
            </SegmentedButtonItem>
          ))}
        </SegmentedButton>
      </div>
    </div>
  );
}
