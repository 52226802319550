import { createBrowserHistory } from 'history';
import { eventBus } from './eureka';
import i18n from 'i18next';

// A singleton history object for easy API navigation
const history = createBrowserHistory({
  getUserConfirmation: (message, callback) => {
    eventBus.emit('on-optional-message-box', '', {
      title: i18n.t('Warning'),
      content: i18n.t('LeavePage_Confirmation_Msg'),
      actions: [i18n.t('LeavePage'), i18n.t('Cancel')],
      checkBoxStyle: { display: 'none' },
      closeCallback: evt => {
        const allowTransition = evt.detail.action === i18n.t('LeavePage');
        callback(allowTransition);
      },
    });
  },
});
export default history;
