import React, { useEffect, useState, useMemo } from 'react';
import { List, ListMode, StandardListItem, Button, ButtonDesign } from '@ui5/webcomponents-react';
import {
  useTranslation,
  Spinner,
  getCurrentLanguage,
  setLanguage,
  configManagerSetLanguage,
  MessageToast,
  getFeatureToggle,
  UtcMapping,
  getThemeId,
  setThemeId,
} from 'src/common/eureka';
import { DST_FF, THEME_FF } from 'src/common/constants';
import { closeShellDialog } from '../../plugins/dialog.plugin';
import { fetchBasicSetup, fetchLanguages, fetchMe, patchMe, fetchTimezones } from './axios';
import LanguageAndRegion from './LanguageAndRegion';
import UserAppearance from './UserAppearance';
import { TimeFormatOptions, TimeFormatTexts } from './options';
import UserAccount from './UserAccount';
import { getThemeById } from 'src/common/Utils';

const initialData = {
  userName: { firstName: '', lastName: '' },
  pictureId: null,
  email: '',
  phone: '',
  language: '',
  dateFormat: '',
  timeFormat: '',
  themeId: '',
};

const TimeZoneOptions = Object.keys(UtcMapping).map(key => {
  return { ...UtcMapping[key], key };
});

const getFullUserName = (userName, familyNameFirst) => {
  if (userName && typeof userName === 'object') {
    return familyNameFirst
      ? userName.lastName + ' ' + userName.firstName
      : userName.firstName + ' ' + userName.lastName;
  }
};

export default function UserProfile({ eventBus }) {
  const { t } = useTranslation(process.env.APP_NAME);
  const [isLoading, setLoading] = useState(false);
  const [currentItem, setCurrentItem] = useState('user-profile_account');
  const [data, setData] = useState({ ...initialData });
  const [originalData, setOriginalData] = useState({ ...initialData });
  const [languages, setLanguages] = useState([]);
  const [timezones, setTimezones] = useState([]);
  const isThemeSettingEnable = getFeatureToggle(THEME_FF);

  const DST_FF_ENABLED = useMemo(() => getFeatureToggle(DST_FF), []);

  const notifyOthers = (user, languageChanged, themeChanged) => {
    eventBus?.emit('configuration-updated', 'user profile', {
      key: 'userProfile',
      data: user,
    });
    if (themeChanged) {
      // tell other mfes, theme is changed
      // no reload in this case
      const themeId = user?.themeId || 'sap_horizon';
      eventBus?.emit('appearance-update', 'user theme', { themeChanged: true, themeId });
    }
  };

  useEffect(() => {
    const getData = () => {
      setLoading(true);
      return Promise.all([
        fetchMe(),
        fetchBasicSetup(),
        fetchLanguages(getCurrentLanguage()),
        fetchTimezones(),
      ])
        .then(results => {
          const me = results[0].data;
          const basicSetup = results[1].data;
          if (me.userUpdateState === null) {
            me.language = basicSetup.language;
            me.dateFormat = basicSetup.dateFormat;
            me.timeFormat = basicSetup.timeFormat;
            me.userUpdateState = 1;
          }
          //setLanguage(me.language);
          me.language = getCurrentLanguage();
          setThemeId(me.themeId);
          setData({
            ...me,
          });
          setOriginalData({
            ...me,
          });
          setLanguages(results[2].data);
          //configManagerSetLanguage(results[2].data);
          setTimezones(results[3]);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    getData();
  }, []);

  const handleCancel = () => {
    closeShellDialog();

    setData({
      ...originalData,
    });
  };

  const onDataPropChange = (value, propName) => {
    if (propName.indexOf('.') > 0) {
      const [objName, subPropName] = propName.split('.');
      setData({
        ...data,
        [objName]: {
          ...data[objName],
          [subPropName]: value,
        },
      });
    } else {
      setData({
        ...data,
        [propName]: value,
      });
    }
  };

  const handleSave = () => {
    const differ = {};
    let languageChanged = false;
    let themeChanged = false;
    Object.keys(data).forEach(key => {
      if (originalData[key] !== data[key]) {
        differ[key] = data[key];
        if (key === 'language') {
          languageChanged = true;
        }
        if (key === 'themeId') {
          themeChanged = true;
        }
      }
    });
    if (Object.keys(differ).length > 0) {
      let current = parseInt(data.userUpdateState);
      current = isNaN(current) ? 1 : current;
      differ.userUpdateState = current + 1;
    }
    setLoading(true);
    return patchMe({ ...differ })
      .then(result => {
        const user = result.data;
        setOriginalData({ ...user });
        setData({ ...user });
        notifyOthers(
          {
            ...user,
            timeZone: DST_FF_ENABLED ? user.profileTimeZone : user.timeZone,
          },
          languageChanged,
          themeChanged,
        );

        if (languageChanged) {
          setLanguage(data.language);
          configManagerSetLanguage(data.language);
        }

        MessageToast.success(t('UserProfile_Msg_UpdateSuccess'));
        closeShellDialog();
      })
      .catch(() => {
        setData({
          ...originalData,
        });
        MessageToast.error(t('UserProfile_Msg_UpdateFailed'));
        setLoading(false);
      });
  };

  return (
    <div>
      <div style={{ display: 'flex', width: '680px', height: '492px' }}>
        <List
          style={{ width: '221px' }}
          mode={ListMode.SingleSelect}
          noDataText={t('UserProfile_Msg_NoDataAvailable')}
          onItemClick={evt => {
            setCurrentItem(evt.detail.item.id);
          }}
        >
          <StandardListItem
            id="user-profile_account"
            icon="employee"
            selected={currentItem === 'user-profile_account'}
            description={getFullUserName(data.userName, data.familyNameFirst)}
          >
            {t('UserProfile_UserAccount')}
          </StandardListItem>
          {isThemeSettingEnable && (
            <StandardListItem
              icon="palette"
              id="user-profile_appearance"
              data-testid="rm-appearance"
              selected={currentItem === 'user-profile_appearance'}
              description={getThemeById(getThemeId())?.displayName}
            >
              {t('UserProfile_Appearance', 'Appearance')}
            </StandardListItem>
          )}
          <StandardListItem
            icon="globe"
            id="user-profile_language_region"
            data-testid="rm-language_region"
            selected={currentItem === 'user-profile_language_region'}
            description={`${(
              (data.language && data.language.split('-')[0]) ||
              '...'
            ).toUpperCase()} | ${
              t(TimeFormatTexts[TimeFormatOptions.indexOf(data.timeFormat)] || '...') || '...'
            }`}
          >
            {t('UserProfile_LanguageAndRegion')}
          </StandardListItem>
        </List>
        <section
          style={{
            flexGrow: 1,
            width: '460px',
            padding: '1rem',
            background: '#F4F4F4',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          {currentItem === 'user-profile_account' &&
            (DST_FF_ENABLED ? (
              <UserAccount data={data} timezones={timezones} onDataPropChange={onDataPropChange} />
            ) : (
              <UserAccount
                data={data}
                timezones={TimeZoneOptions}
                onDataPropChange={onDataPropChange}
              />
            ))}
          {currentItem === 'user-profile_appearance' && (
            <UserAppearance data={data} onDataPropChange={onDataPropChange} />
          )}
          {currentItem === 'user-profile_language_region' && (
            <LanguageAndRegion
              data={data}
              languages={languages}
              onDataPropChange={onDataPropChange}
            />
          )}
        </section>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          height: 'var(--_ui5_popup_default_header_height)',
          borderTop: '1px solid var(--sapPageFooter_BorderColor)',
        }}
      >
        <Button
          disabled={JSON.stringify(data) === JSON.stringify(originalData)}
          design={ButtonDesign.Emphasized}
          onClick={handleSave}
          data-testid="saveButton"
        >
          {t('Save')}
        </Button>
        <Button
          style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}
          onClick={handleCancel}
          data-testid="cancelButton"
          design={ButtonDesign.Transparent}
        >
          {t('Cancel')}
        </Button>
      </div>
      {isLoading && (
        <div
          style={{
            top: 0,
            width: '100%',
            height: '100%',
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            background: 'rgba(0, 0, 0, 0.01)',
            zIndex: 999,
          }}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
}
