// record all the feature flags currently in use
/**
 * {
 *    name: '', // same name as from dev portal
 *    description: '', // just for our use to general info about that feature toggle
 *    currentValue: bool, // value used when running mock server
 * }
 */

export const featureToggles = {
  DISABLE_DEMO_TOOL: {
    name: 'RM.27196.DISABLE.DEMO.TOOL',
    description: 'Disable the demo tool',
    currentValue: false,
  },
  BUILT_IN_SUPPORT: {
    name: 'RM.27822.Built.In.Support',
  },
};

// constants to be used for tests
export const ALL_FF_OFF = 'ALL_FF_OFF';

/* Requirements
  1. Change feature flag values during development to see its effect
  2. While testing, setup the default values of the feature flags during setup
  */
