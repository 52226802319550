import eureka from 'eureka';
import eurekaMgrs from '@eureka/ui-managers';

const { Spinner } = eureka.components;
const { MessageToast, OptionalMessageBox } = eureka.controls;
const { i18n, initI18n, useTranslation, getCurrentLanguage, setLanguage, withTranslation } =
  eureka.I18nProvider;
const { UtcMapping } = eureka.DateManager;
const { eventBus } = eurekaMgrs;
const { axiosCreate } = eureka.HttpWrapper;

const {
  addConfig,
  getConfig,
  setCsrfToken,
  getCsrfToken,
  setLanguage: configManagerSetLanguage,
  getLanguage: configManagerGetLanguage,
  updateFeatureToggle,
  getFeatureToggle,
  setDebug,
} = eurekaMgrs.ConfigManager;
const { ProxyHelper } = eurekaMgrs.ProxyManager;
const AppearanceManager = eurekaMgrs.AppearanceManager;
const {
  getDefaultThemeId,
  getDefaultThemeList,
  getThemeList,
  getThemeId,
  setThemeId,
  setThemeList,
  setDefaultThemeId,
} = eurekaMgrs.AppearanceManager;

export {
  Spinner,
  MessageToast,
  i18n,
  initI18n,
  useTranslation,
  getCurrentLanguage,
  setLanguage,
  withTranslation,
  UtcMapping,
  eventBus,
  axiosCreate,
  addConfig,
  getCsrfToken,
  setCsrfToken,
  getConfig,
  OptionalMessageBox,
  configManagerSetLanguage,
  configManagerGetLanguage,
  updateFeatureToggle,
  getFeatureToggle,
  ProxyHelper,
  setDebug,
  getDefaultThemeId,
  getDefaultThemeList,
  getThemeList,
  getThemeId,
  setThemeId,
  setThemeList,
  setDefaultThemeId,
  AppearanceManager,
};
