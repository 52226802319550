import axiosWrapper from './../../axios/axiosWrapper';

export const getProductConfig = async () => {
  const { data } = await axiosWrapper().fetchAuth();
  return {
    PPMSIDs: ['73555000100200015051', '73555000100100009421', '73555000100800003211', '73554900100700002901'],
    hcpSubAccountID: data.zid,
  };
};

export const getApplicationConfig = function () {
  return {
    applicationName: 'SAP Intelligent Returns Management',
    achComponent: 'IS-IRM',
  };
};

export const getRecommendedConfig = function (t) {
  return [
    {
      text: t('Administration_Guide'),
      description: t('Administration_Guide_description'),
      url: 'https://help.sap.com/docs/returns/fb041e275d964ef0944a80f2592d411a',
    },

    {
      text: t('Security_Guide'),
      description: t('Security_Guide_description'),
      url: 'https://help.sap.com/docs/returns/cfd4dd8f8b1a4ccfb876e1847c27b886',
    },

    {
      text: t('FAQ_Trouble_Shoot'),
      description: t('FAQ_Trouble_Shoot_description'),
      url: 'https://help.sap.com/docs/returns/aff2b590715e4c7d97763d53844228a3',
    },

    {
      text: t('Whats_New'),
      description: t('Whats_New_description'),
      url: 'https://help.sap.com/docs/returns/a3c6a94a07884e42bc61cdc7d9b41778',
    },
  ];
};
