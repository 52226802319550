import eureka from 'eureka';
const { axiosCreate } = eureka.HttpWrapper;

const axiosInstance = axiosCreate({
  baseURL: '/',
  headers: {
    accept: '*/*',
    'X-Tenant-Schema': '1',
    'X-User-ID': '1',
  },
  timeout: 10000,
}).instance;

const pushDeliveryStatus = ({ returnOrderId, returnType, status }) =>
  axiosInstance.post(`api/return-core/business/v1/test/delivery-status`, null, {
    params: {
      returnOrderId,
      returnType,
      status,
    },
  });

export const handleCarrierReceived = (returnOrderId, returnType) =>
  pushDeliveryStatus({ returnOrderId, returnType, status: 'CARRIER_RECEIVED_PKG' });

export const handleInTransit = (returnOrderId, returnType) =>
  pushDeliveryStatus({ returnOrderId, returnType, status: 'IN_TRANSIT' });

export const handleDelivered = (returnOrderId, returnType) =>
  pushDeliveryStatus({ returnOrderId, returnType, status: 'DELIVERED' });

export const putAutoTransitEnabled = enabledAutomatedLogisticStatusEmulation =>
  axiosInstance.put('api/return-core/business/v1/test/internal-settings', {
    enabledAutomatedLogisticStatusEmulation,
  });

export const getAutoTransitEnabled = () =>
  axiosInstance.get('api/return-core/business/v1/test/internal-settings');
