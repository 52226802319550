import { useMemo } from 'react';
import { Avatar, Input, Label, AvatarShape, Select, Option } from '@ui5/webcomponents-react';
import { useTranslation, getFeatureToggle } from 'src/common/eureka';
import { DST_FF } from 'src/common/constants';
import useStyles from './UserAccount.styles';

const formItemStyle = {
  width: '100%',
  margin: '10px auto',
  display: 'flex',
  alignItems: 'center',
};

const labelStyle = {
  width: '100%',
};

export default function UserAccount({ data, onDataPropChange, timezones }) {
  const { t } = useTranslation(process.env.APP_NAME);
  const classes = useStyles();
  let avatar = data?.avatarIcon || {};
  const type = avatar.type?.toLocaleLowerCase();
  const value = avatar.value;

  const DST_FF_ENABLED = useMemo(() => getFeatureToggle(DST_FF), []);

  return (
    <div style={{ height: '100%', width: '80%' }}>
      <div className={classes.avatarContainer}>
        <Avatar
          initials={type === 'initials' ? value : ''}
          image={type === 'image' ? value : null}
          type={type}
          icon={type === 'genderneutralicon' ? 'employee' : ''}
          shape={AvatarShape.Circle}
          size="XL"
        />
      </div>
      <div>
        {data.familyNameFirst ? (
          <>
            <div style={{ ...formItemStyle }}>
              <Label showColon={true} style={{ ...labelStyle }}>
                {t('UserProfile_FamilyName')}
              </Label>
              <Input
                value={data.userName?.lastName || ''}
                onChange={evt => {
                  onDataPropChange(evt.target.value, 'userName.lastName');
                }}
                data-testid="lastName"
              />
            </div>
            <div style={{ ...formItemStyle }}>
              <Label showColon={true} style={{ ...labelStyle }}>
                {t('UserProfile_FirstName')}
              </Label>
              <Input
                value={data.userName?.firstName || ''}
                onChange={evt => {
                  onDataPropChange(evt.target.value, 'userName.firstName');
                }}
                data-testid="firstName"
              />
            </div>
          </>
        ) : (
          <>
            <div style={{ ...formItemStyle }}>
              <Label showColon={true} style={{ ...labelStyle }}>
                {t('UserProfile_FirstName')}
              </Label>
              <Input
                value={data.userName?.firstName || ''}
                onChange={evt => {
                  onDataPropChange(evt.target.value, 'userName.firstName');
                }}
                data-testid="firstName"
              />
            </div>
            <div style={{ ...formItemStyle }}>
              <Label showColon={true} style={{ ...labelStyle }}>
                {t('UserProfile_FamilyName')}
              </Label>
              <Input
                value={data.userName?.lastName || ''}
                onChange={evt => {
                  onDataPropChange(evt.target.value, 'userName.lastName');
                }}
                data-testid="lastName"
              />
            </div>
          </>
        )}
        <div style={{ ...formItemStyle }}>
          <Label showColon={true} style={{ ...labelStyle }}>
            {t('UserProfile_ID')}
          </Label>
          <Input readonly={true} value={data.s4ExternalUserId || ''} data-testid="externalUserId" />
        </div>
        <div style={{ ...formItemStyle }}>
          <Label showColon={true} style={{ ...labelStyle }}>
            {t('UserProfile_Email')}
          </Label>
          <Input
            disabled={true}
            value={data.email || ''}
            onChange={evt => {
              onDataPropChange(evt.target.value, 'email');
            }}
            data-testid="email"
          />
        </div>
        <div style={{ ...formItemStyle }}>
          <Label showColon={true} style={{ ...labelStyle }}>
            {t('UserProfile_Phone')}
          </Label>
          <Input
            value={data.phone || ''}
            onChange={evt => {
              onDataPropChange(evt.target.value, 'phone');
            }}
            data-testid="phone"
          />
        </div>
        <div style={{ ...formItemStyle }}>
          <Label showColon={true} style={{ ...labelStyle }}>
            {t('UserProfile_TimeZone')}
          </Label>
          {DST_FF_ENABLED ? (
            <Select
              style={{ width: '210px' }}
              value={data.profileTimeZone}
              onChange={({ detail }) => {
                onDataPropChange(detail.selectedOption.dataset.id, 'profileTimeZone');
              }}
              data-testid="timeZone"
            >
              {timezones?.map(({ displayName, timezone }) => {
                return (
                  <Option
                    key={timezone}
                    data-id={timezone}
                    value={timezone}
                    selected={timezone === data.profileTimeZone}
                  >
                    {displayName}
                  </Option>
                );
              })}
            </Select>
          ) : (
            <Select
              style={{ width: '210px' }}
              value={data.timeZone}
              onChange={({ detail }) => {
                onDataPropChange(detail.selectedOption.dataset.id, 'timeZone');
              }}
              data-testid="timeZone"
            >
              {timezones.map(({ key, description }) => {
                return (
                  <Option key={key} data-id={key} value={key} selected={key === data.timeZone}>
                    {description}
                  </Option>
                );
              })}
            </Select>
          )}
        </div>
      </div>
    </div>
  );
}
