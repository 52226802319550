import { configManagerGetLanguage, AppearanceManager } from '../../eureka';
import { getBuiltInSupportConfig } from './../bisConfig';
import { getProductConfig, getApplicationConfig, getRecommendedConfig } from './../bisContext';
import SdkHelpers from './sdkHelpers';
import loadSdkScript from './loadSdkScript';

const { url, env } = getBuiltInSupportConfig();

const getSDK = async () => {
  if (!SdkHelpers.getSdk()) {
    let { sdk } = await loadSdkScript(`${url}/dsx-everywhere-sdk/sdk-react-dsx-everywhere.js`);
    SdkHelpers.setSdk(sdk);
    return sdk;
  }
  return SdkHelpers.getSdk();
};

export const openDSX = async t => {
  const sdk = await getSDK();
  if (sdk) {
    if (!sdk.isInitialized()) {
      sdk.create({
        version: 'V1',
        targetEnvironment: env,
        useIcon: false,
        topOffset: '44px',
        getProductContext: async function () {
          const { PPMSIDs, hcpSubAccountID } = await getProductConfig();
          return new Promise(resolve => {
            resolve({
              productVersion: 'SAP INTELLIGENT RETURNS MT 1.0',
              language: configManagerGetLanguage(),
              PPMSIDs,
              systemRole: 'IRM',
              hcpSubAccountID,
            });
          });
        },
        getApplicationContext: function () {
          const { applicationName, achComponent } = getApplicationConfig();
          return new Promise(resolve => {
            resolve({
              applicationName,
              achComponent,
            });
          });
        },
        getUsersTheme: function () {
          return new Promise(resolve => {
            resolve({
              theme: AppearanceManager.getThemeId(),
            });
          });
        },
        getRecommendedLinks: function () {
          return new Promise(resolve => {
            resolve({
              recommendedLinks: getRecommendedConfig(t),
            });
          });
        },
      });
      sdk.openWindow();
    } else {
      sdk.publish('dsx-trigger-context-update', 'getUsersTheme');
      sdk.refreshContext(() => {
        return {
          recommendedLinks: getRecommendedConfig(t),
          language: configManagerGetLanguage(),
        };
      });
      sdk.isWindowOpened() ? sdk.closeWindow() : sdk.openWindow();
    }
  }
};
