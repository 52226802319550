import { useState, useEffect, createRef } from 'react';
import { Dialog } from '@ui5/webcomponents-react';
import { eventBus } from 'src/common/eureka';

/**
 * Don't suggest using it since the Dialog has the dynamic padding
 */
export const ShellDialog = () => {
  const dialogRef = createRef();
  const [properties, setProperties] = useState({
    showDialog: false,
    content: '',
    hideHeader: true,
    footer: null,
    stretch: false,
    title: '',
  });
  /* istanbul ignore next */
  useEffect(() => {
    let dialogCurrent = dialogRef && dialogRef.current;
    eventBus.on('dialog-show-hide', command => {
      let { data } = command;
      if (data) {
        let ndata = { ...data };
        ndata.showDialog = data.showDialog;
        if (data.content) {
          ndata.content = typeof data.content === 'function' ? data.content() : data.content;
        }
        setProperties({
          ...properties,
          ...ndata,
        });

        if (ndata.showDialog) {
          dialogCurrent?.show();
        } else {
          dialogCurrent?.close();
        }
      }
    });

    return () => {
      dialogCurrent = null;
      eventBus.detach('dialog-show-hide');
    };
  }, [dialogRef, properties]);
  return (
    <Dialog
      ref={dialogRef}
      open={properties.showDialog}
      className="dialog-modal"
      onClose={() => {
        /* istanbul ignore next */
        setProperties({
          ...properties,
          showDialog: false,
        });
        /* istanbul ignore next */
        dialogRef.current.close();
      }}
      stretch={properties.stretch}
      headerText={properties.title}
      footer={properties.footer}
    >
      {properties.content}
    </Dialog>
  );
};
/* istanbul ignore next */
export const closeShellDialog = () => {
  eventBus.emit('dialog-show-hide', '', {
    type: 'cancel',
    data: {
      showDialog: false,
    },
  });
};
